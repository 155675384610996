<script>
import { computed, watch } from 'vue';
import {
  fetchPageBuilder,
  OptionsComponent,
  loadingProgressKey,
  navigateKey,
  routeKey,
  subscribeKey,
  unsubscribeKey,
  getItemKey
} from '@drapejs/core';
import { setCategoryMeta } from '@/composables/useMeta';

import { getCategoryCacheKey } from '@/composables/useProductListing';
import * as category from '../../connectors/litium/category';

import { searchMixin } from '@/components/SearchView/search';

import EmbeddedFacets from '../components/EmbeddedFacets';
import SortSelector from '../components/SortSelector';

import ProductCard from '../components/ProductCard.vue';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import BackToTopButton from '../components/BackToTopButton.vue';

export default {
  extends: OptionsComponent,
  components: {
    EmbeddedFacets,
    SortSelector,
    ProductCard,
    LoadingSpinner,
    BackToTopButton
  },
  data() {
    return {
      currentCacheKey: '',
      productData: null,
      loadAllRequestPending: false
    };
  },
  mixins: [searchMixin],
  inject: {
    loadingProgress: loadingProgressKey,
    navigate: navigateKey,
    route: routeKey,
    subscribe: subscribeKey,
    unsubscribe: unsubscribeKey,
    getItem: getItemKey
  },
  provide() {
    return {
      productDisplayCountCaption: computed(
        () => this.productDisplayCountCaption
      ),
      productData: computed(() => this.productData)
    };
  },
  computed: {
    isLoading() {
      if (!this.$page) return false;

      if (this.productData) return false;
      return this.loadingProgress !== null;
    },
    hasNoProducts() {
      return !this.productData;
    },
    productDisplayCountCaption() {
      const hits = this.productData?.hits > 0 ? this.productData?.hits : '0';

      return this.$replaceTokens(this.$globalTexts.category_products_show, {
        count: hits
      });
    },
    showProductDisplayCount() {
      if (!this.productData?.hits || this.productData?.hits == -1) {
        return false;
      }
      return true;
    },
    name() {
        return this.$page.dataJson?.dataJson?.category?.name;
    },
    parents() {
      return this.$page.dataJson?.dataJson?.category?.parents || [];
    },
    hasParents() {
      return this.parents.length > 0;
    },
    categorySegments() {
      return this.productData?.categorySegments || [];
    },
    categoryProducts() {
      return this.productData?.products || [];
    },
    canLoadAllProducts() {
      const hits = this.categoryProducts?.length || 0;
      const totalHits = this.productData?.hits || 0;

      return hits < totalHits;
    },
    category() {
      return this.$page.dataJson?.dataJson?.category;
    },
    seo() {
      return this.category?.seo;
    },
    productsLeftToFetchCaption() {
      const hits = this.categoryProducts?.length || 0;
      const totalHits = this.productData?.hits || 0;

      const amount = totalHits - hits;

      if (amount > 0) {
        return this.$replaceTokens(
          this.$globalTexts.category_products_left_to_fetch ||
            'category_products_left_to_fetch {{amount}}',
          {
            amount
          }
        );
      }

      return '';
    }
  },
  watch: {
    categoryProducts() {
      if (this.hasNoProducts) {
        return;
      }

      if (this.route.query.search && !this.route.query.facets) {
        this.pushSearchEvent({
          searchPhrase: this.route.query.search,
          productHitCount: this.productData?.hits || 0,
        });
      }

      this.pushViewItemListEvent();
      this.pushViewPromotionEvent();
    }
  },
  methods: {
    setProductData(val) {
      if (typeof val?.facets !== 'undefined') { // Fixes an issue where facets would disappear after filtering
        this.productData = val;
      }
    },
    pushViewPromotionEvent() {
      if (typeof dataLayer === 'undefined') {
        return;
      }

      const items = this.categoryProducts
        .slice(this.skip)
        .filter((i) => i.campaignPrice && i.campaignPrice < i.price)
        .map((i) => ({
          item_id: i.articleNumber,
          item_name: i.googleAnalyticsName || i.productName
        }));
      if (items.length > 0) {
        const dataToPush = {
          event: 'view_promotion',
          items
        };

        dataLayer.push(dataToPush);
      }
    },
    pushViewItemListEvent() {
      if (typeof dataLayer === 'undefined') {
        return;
      }

      const items = this.categoryProducts.slice(this.skip).map((p) => ({
        item_id: p.articleNumber,
        item_name: p.googleAnalyticsName || p.productName
      }));

      const dataToPush = {
        event: 'view_item_list',
        item_list_id: `category_${this.category.systemId}`,
        item_list_name: `Category: ${this.category.googleAnalyticsName}`,
        items
      };

      dataLayer.push(dataToPush);
    },
    async updateSorting(sortValue) {
      this.skip = 0;
      const query = Object.create(null);
      query.sort = encodeURIComponent(sortValue);
      const routeArgs = {
        ...this.$route.query,
        ...query
      };
      await this.updateBrowserUrl(routeArgs);
    },
    async updateFacets(facets) {
      this.skip = 0;

      const query = Object.create(null);

      const browserQuery = { ...this.$route.query };
      delete browserQuery.search;

      if (facets.facetsUrl || this.$route?.query?.facets) {
        query.facets = encodeURIComponent(facets.facetsUrl);
      }

      const searchValue = facets.searchValue?.trim();
      if (searchValue) {
        query.search = encodeURIComponent(searchValue);
      }

      const routeArgs = {
        ...browserQuery,
        ...query
      };

      await this.updateBrowserUrl(routeArgs);
    },
    async updateBrowserUrl(routeArgs) {
      const queryParams = Object.keys(routeArgs)
        .filter((k) => routeArgs[k])
        .map((k) => [k, encodeURIComponent(routeArgs[k])].join('='))
        .join('&');

      const url = queryParams
        ? [this.$route.pathname, queryParams].join('?')
        : this.$route.pathname;

      await this.navigate(url, {
        replaceState: true
      });
    },
    async loadAllProducts() {
      try {
        this.loadAllRequestPending = true;
        const newPageRequest = fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          { ...this.$route.query },
          ''
        );
        newPageRequest.categorySystemId = this.productData?.categorySystemId;

        await this.$invoke(category.commands.loadAllProducts, newPageRequest);
      } finally {
        this.loadAllRequestPending = false;
      }
    }
  },
  async serverPrefetch() {
    const currentCacheKey = getCategoryCacheKey(
      this.route.pathname,
      this.route.query
    );

    this.productData = await this.getItem(currentCacheKey);
  },
  mounted() {
    setCategoryMeta();
    watch(
      () => this.route.href,
      () => {
        if (this.currentCacheKey) {
          this.unsubscribe(this.currentCacheKey, this.setProductData);
        }

        this.currentCacheKey = getCategoryCacheKey(
          this.route.pathname,
          this.route.query
        );

        this.subscribe(this.currentCacheKey, this.setProductData, {
          immediate: true
        });
      },
      {
        immediate: true
      }
    );
  },
  beforeUnmount() {
    if (this.currentCacheKey) {
      this.unsubscribe(this.currentCacheKey, this.setProductData);
    }
  }
};
</script>

<template>
  <div>
    <back-to-top-button target-class="category__product-list-wrapper" />
    <div class="category">
      <!-- Bread crumbs removed for now as Vinga only has 2 levels -->
      <!-- <div class="category__breadcrumbs" v-if="hasParents">
        <span
          v-for="parent in parents"
          :key="parent.systemId"
          class="category__breadcrumbs-parent"
        >
          <router-link :to="parent.url">{{ parent.name }}</router-link>
          <span class="category__breadcrumbs-separator">/</span>
        </span>
      </div>-->
      <div class="category__menu">
        <h4 class="category__breadcrumbs-current">{{ name }}</h4>
        <embedded-facets @update:facets="updateFacets" />

        <div class="category__info-and-sort">
          <div class="category__listing-info" v-if="showProductDisplayCount">
            {{ productDisplayCountCaption }}
          </div>
          <sort-selector
            class="category__sort"
            @update:sortValue="updateSorting"
          />
        </div>
      </div>
      <div
        class="category__product-list-wrapper"
        :class="{ 'category__product-list-wrapper--loading': isLoading }"
      >
        <div class="category__product-list-spinner-container">
          <loading-spinner
            v-if="isLoading"
            class="category__product-list-spinner"
            :text="$globalTexts.category_products_loading"
          />
        </div>
        <div class="category__product-list">
          <template
            v-for="product in categoryProducts"
            :key="product.articleNumber"
          >
            <product-card :product="product" class="category__product" />
          </template>
        </div>
        <div class="category__product-actions">
          <div
            class="category__fetch-all-products"
            v-if="!loadAllRequestPending"
          >
            <div class="category__left-to-fetch-caption">
              {{ productsLeftToFetchCaption }}
            </div>
            <button
              class="primary-button category__load-more-products"
              v-if="canLoadAllProducts"
              @click="loadAllProducts()"
            >
              <span>{{
                $globalTexts.category_products_fetch_all ||
                'category_products_fetch_all'
              }}</span>
            </button>
          </div>
          <loading-spinner
            v-else
            :text="$globalTexts.category_products_loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.category__breadcrumbs {
  font-weight: 400;
  padding: 14px 0.8rem;
  margin: 0 auto 8px;
  font-size: 16px;
  background: var(--color-dark-grey);
  color: white;
  letter-spacing: 0.59px;
  display: flex;
  line-height: 24px;
  align-items: center;
}

.category__breadcrumbs-parent,
.category__breadcrumbs-parent a {
  color: black;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.category__breadcrumbs
  .category__breadcrumbs-parent:last-child
  .category__breadcrumbs-separator {
  display: none;
}

.category__breadcrumbs-current {
  margin-left: 0.75rem;
  margin-top: 1.25rem;
  margin-bottom: 0;
  text-align: left;
}

.category__menu {
  max-width: calc(1221px + 2rem);
  margin: -1rem auto auto;
}

.category__option-trash-icon {
  height: 15px;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.category__info-and-sort {
  display: flex;
  justify-content: space-between;
  margin: 0.85rem 0.8rem 0.9rem;
  font-size: 12px;
  font-weight: 600;
}

.category__breadcrumbs-separator {
  padding: 0 0.2rem;
}

.category__product-list-wrapper {
  position: relative;
}

.category__product-list {
  display: flex;
  flex-wrap: wrap;
  margin: auto 0.5rem;
}

.category__product-list-spinner-container {
  position: sticky;
  top: 5rem;
  left: 1rem;
  right: 1rem;
  z-index: 10;
  pointer-events: none;
}

.category__product-list-spinner {
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
}

.category__product-list-wrapper--loading .category__product-list,
.category__product-list-wrapper--loading .category__product-actions {
  opacity: 0.5;
}

.category__product-actions {
  margin-bottom: 1rem;
}

button.category__load-more-products.category__load-more-products {
  margin: 0.5rem auto 1.5rem;
  display: flex;
  justify-self: center;
  align-self: center;
  width: calc(100% - 2rem);

  @media (--tabletAndDesktop) {
    width: auto;
  }
}

.category__load-more-products span {
  text-align: center;
  width: 100%;
}

.category__product {
  width: 50%;
}

.category__left-to-fetch-caption {
  padding: 0 0 0.5rem 0;
  font-weight: 700;
  text-align: center;
  font-size: 15px;
}

@media (max-width: 320px) {
  .category__product {
    width: 100%;
  }
}

@media (--tabletAndDesktop) {
  .category__menu {
    margin: auto;
  }

  .category__product-list-wrapper {
    max-width: calc(1210px + 1.5rem);
    margin: auto;
  }

  .category__product-list {
    margin: 2rem 0 0;
  }

  .category__product {
    width: 25%;
    margin-bottom: 20px;
  }

  .category__breadcrumbs-parent,
  .category__breadcrumbs-parent a {
    color: black;
  }

  .category__breadcrumbs-current {
    margin-left: 1.25rem;
    margin-top: 1.75rem;
    font-weight: 700;
  }

  .category__breadcrumbs {
    max-width: 1210px;
    padding: 1.5rem 1rem 0.5rem;
    background: none;
    font-weight: 800;
    font-size: 18px;
  }

  .category__info-and-sort {
    flex-direction: column-reverse;
    margin: 0.5rem 1.25rem 0.6rem 1.5rem;
  }

  .category__listing-info {
    font-size: 18px;
    margin: 1rem 0 -1rem;
  }

  .category__sort {
    align-self: flex-end;
  }

  .category__load-more-products {
    width: auto;
  }
}
</style>
